import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useStableCallback } from '../../libs/react-use-extra';
import { customFonts } from './BEE_fonts';
import { GroupPermissionModal } from './groupPermissionModal';
import { useToast } from '../../redux/action';
import newTemplate from './newTemplateFile';
import {
  saveRow,
  getRows,
  getRowCategories,
  deleteRow,
  editRow,
} from './savedRows/api';
import SavedRowsModal from './savedRows';
const CLIENT_ID = `${process.env.REACT_APP_BEE_CLIENT}`;
const CLIENT_SECRET = `${process.env.REACT_APP_BEE_SECRET}`;

export default function Bee({
  clientUid,
  onLoad,
  onSave,
  onChange,
  beeEditor,
  className,
  currentDesign,
  onComment,
  onSend,
  AB,
}) {
  const [open, setOpen] = useState(false);
  const [isSavedRowsModalVisible, setSavedRowsModalVisible] = useState(false);
  const [orgRows, setOrgRows] = useState([]);
  const [dynamicRowsConfiguration, setDynamicRowsConfiguration] = useState({
    emptyRows: true,
    defaultRows: false,
    externalContentURLs: [],
  });
  const [modalProps, setModalProps] = useState({});
  const isBeeEditorStarted = useRef(null);
  const { errorToast } = useToast();

  useEffect(() => {
    const getAsyncDynamicRows = async () => {
      try {
        const dynamicRows = await getRowCategories();
        const rows = await getRows();
        setDynamicRowsConfiguration(dynamicRows);
        setOrgRows(rows);
      } catch (e) {
        console.error('Error in getAsyncDynamicRows:', e);
        errorToast('Encountered an error while fetching saved rows.');
      }
    };

    getAsyncDynamicRows();
  }, []);

  const displayResolution = useRef(null);

  const { userHandle, username, userColor, beeAdmin } = useSelector(state => ({
    userHandle: state.user.detail._id,
    username: `${state.user.detail.firstName} ${state.user.detail.lastName}`,
    userColor: state.user.detail.avatarColor,
    beeAdmin: state.user.userPrivileges?.beeAdmin,
  }));

  const editorFonts = {
    showDefaultFonts: true,
    customFonts: customFonts,
  };

  const handleGetRows = async (resolve, reject, args) => {
    if (!orgRows.length) {
      try {
        const rows = await getRows();
        setOrgRows(rows);
        resolve(rows[args.handle] || []);
      } catch (e) {
        console.error('Error in handleGetRows:', e);
        errorToast('Encountered an error while fetching saved rows.');
      }
    } else {
      resolve(orgRows[args.handle] || []);
    }
  };

  const handleSaveRow = async (resolve, reject, row) => {
    try {
      const result = await new Promise((modalResolve, modalReject) => {
        setModalProps({
          resolve: modalResolve,
          reject: modalReject,
          args: {
            row,
            isNew: true,
            onHide: () => setSavedRowsModalVisible(false),
          },
        });
        setSavedRowsModalVisible(true);
      });
      if (result && result.name && result.category && result.categoryId) {
        resolve(
          {
            name: result.name,
            id: uuidv4(),
            category: result.category,
            categoryId: result.categoryId,
          },
          { synced: false }
        );
      } else {
        reject('Save cancelled or no name provided');
      }
    } catch (error) {
      reject(error);
    } finally {
      setSavedRowsModalVisible(false);
    }
  };

  const handleSaveRowToDB = async rowTemplate => {
    try {
      const row = JSON.parse(rowTemplate);
      await saveRow({
        name: row.metadata.name,
        content: row,
        categoryId: row.metadata.categoryId,
        id: row.metadata.id,
      });
    } catch (e) {
      console.error('Error in handleSaveRowToDB:', e);
      errorToast('Encountered an error while saving a row.');
    }
  };

  const handleOnDeleteRow = async (resolve, reject, { row }) => {
    try {
      if (!row) return;
      await deleteRow(row.metadata.id);
      resolve(true);
    } catch (e) {
      console.error('Error in handleOnDeleteRow:', e);
      errorToast('Encountered an error while deleting a row.');
    }
  };

  const handleOnEditRow = async (resolve, reject, { row }) => {
    if (!row) return;

    try {
      const result = await new Promise((modalResolve, modalReject) => {
        setModalProps({
          resolve: modalResolve,
          reject: modalReject,
          args: {
            row,
            onHide: () => setSavedRowsModalVisible(false),
          },
        });
        setSavedRowsModalVisible(true);
      });
      if (result && result.name && result.category && result.categoryId) {
        row.metadata.name = result.name;
        row.metadata.category = result.category;
        row.metadata.categoryId = result.categoryId;
        await editRow({
          rowId: row.metadata.id,
          content: row,
          name: result.name,
          categoryId: result.categoryId,
        });
        resolve(true);
      } else {
        reject('Edit cancelled or no name provided');
      }
    } catch (error) {
      errorToast('Encountered an error while editing a row.');
      reject(error);
    } finally {
      setSavedRowsModalVisible(false);
    }
  };

  const hooks = {
    getRows: {
      handler: handleGetRows,
    },
  };

  const onCloseConditionDialog = () => {
    setOpen(false);
    displayResolution.current?.reject();
  };

  const saveDisplayConditions = (groupIds, groupNames) => {
    if (!groupIds?.length) displayResolution.current?.reject();
    displayResolution.current?.resolve({
      type: 'group',
      label: 'Applied Group Filters',
      description: groupNames?.join(', ') ?? '',
      before: `{{#if (subscribedToGroups '${groupIds.join(',')}') }}`,
      after: '{{/if}}',
    });
    setOpen(false);
  };

  const beeConfig = {
    uid: clientUid,
    container: 'bee-plugin-container',
    language: 'en-US',
    roleHash: beeAdmin ? 'nr_admin_0' : 'nr_staff_0',
    username,
    userColor,
    userHandle,
    trackChanges: true,
    contentDialog: {
      rowDisplayConditions: {
        label: 'Apply Group Filters',
        handler: (resolve, reject) => {
          setOpen(true);
          displayResolution.current = { resolve, reject };
        },
      },
      saveRow: {
        handler: handleSaveRow,
      },
      onDeleteRow: {
        handler: handleOnDeleteRow,
      },
      onEditRow: {
        handler: handleOnEditRow,
      },
    },
    editorFonts,
    onSave: useStableCallback(onSave),
    onChange: useStableCallback(onChange),
    onLoad: useStableCallback(onLoad),
    onComment: useStableCallback(onComment),
    onSend: useStableCallback(onSend),
    onError: error => console.error('bee editor error:', error),
    onWarning: warning => console.warn('bee editor warning:', warning),
    onSaveRow: handleSaveRowToDB,
    saveRows: true,
    rowsConfiguration: dynamicRowsConfiguration,
    hooks,
  };

  const loadEditor = async () => {
    const loadTemplate =
      !currentDesign?.page || currentDesign === '{}'
        ? newTemplate
        : currentDesign;

    await beeEditor.start(beeConfig, loadTemplate);
    isBeeEditorStarted.current = true;
  };

  const loadBeeConfig = async () => {
    if (isBeeEditorStarted.current) {
      await beeEditor.loadConfig(beeConfig);
    } else {
      loadEditor();
    }
  };

  useEffect(() => {
    beeEditor
      .getToken(CLIENT_ID, CLIENT_SECRET)
      .then(loadEditor)
      .catch(error => {
        console.error('Error in loading bee editor', error);
      });
  }, []);

  useEffect(() => {
    // reloads editor when AB version changes
    if (AB) {
      loadEditor();
    }
  }, [AB]);

  useEffect(() => {
    // reloads editor when dynamicRowsConfiguration changes
    if (dynamicRowsConfiguration?.externalContentURLs?.length) {
      loadBeeConfig();
    }
  }, [dynamicRowsConfiguration]);

  return (
    <>
      <div id="bee-plugin-container" className={className} />
      {isSavedRowsModalVisible && (
        <SavedRowsModal
          {...modalProps}
          setDynamicRowsConfiguration={setDynamicRowsConfiguration}
          dynamicRowsConfiguration={dynamicRowsConfiguration}
        />
      )}
      <GroupPermissionModal
        isOpen={open}
        handleClose={onCloseConditionDialog}
        onSave={saveDisplayConditions}
      />
    </>
  );
}
